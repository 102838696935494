import Swal from 'sweetalert2';

export default function signupReducer(state, action) {
  switch (action.type) {
    case 'FIRST_NAME':
      return { ...state, firstName: action.payload };
    case 'INFIX':
      return { ...state, infix: action.payload };
    case 'FAMILY_NAME':
      return { ...state, familyName: action.payload };
    case 'POSITION_ROLE':
      return { ...state, positionRole: action.payload };
    case 'PHONE_NUMBER':
      return { ...state, phoneNumber: action.payload };
    case 'EMAIL':
      return { ...state, email: action.payload };
    case 'PASSWORD':
      return { ...state, password: action.payload };
    case 'CONFIRM_PASSWORD':
      return { ...state, confirmPassword: action.payload };
    case 'ACCESS_CODE':
      return { ...state, accessCode: action.payload };
    case 'TERMS_AND_CONDITIONS':
      return { ...state, termsAndConditions: action.payload };
    case 'OFFICE_NAME':
      return { ...state, officeName: action.payload };
    case 'VALIDATE': {
      const errors = [];
      let canSubmit = true;

      if (state.firstName.length === 0) {
        errors.push('Voornaam is vereist');
        canSubmit = false;
      }
      if (state.familyName.length === 0) {
        errors.push('Achternaam is vereist');
        canSubmit = false;
      }
      if (state.positionRole.length === 0) {
        errors.push('Beroep is vereist');
        canSubmit = false;
      }
      if (state.phoneNumber.length === 0) {
        errors.push('Telefoonnummer is vereist');
        canSubmit = false;
      }
      if (state.email.length === 0) {
        errors.push('Emailadres is vereist');
        canSubmit = false;
      }
      if (state.password.length === 0) {
        errors.push('Wachtwoord is vereist');
        canSubmit = false;
        // eslint-disable-next-line max-len
      } else if (state.password.length < 8 || !state.password.match(/[0-9]/) || !state.password.match(/[A-Z]/) || !state.password.match(/[a-zA-Z0-9]/)) {
        errors.push('Wachtwoord moet minimaal 8 tekens bevatten, waarvan minimaal 1 hoofdletter, 1 cijfer en 1 speciaal teken');
        canSubmit = false;
      }
      if (state.password !== state.confirmPassword) {
        errors.push('Wachtwoorden komen niet overeen');
        canSubmit = false;
      }
      if (state.accessCode.length === 0) {
        errors.push('Toegangscode is vereist');
        canSubmit = false;
      }
      if (!state.termsAndConditions) {
        errors.push('Algemene voorwaarden moeten worden geaccepteerd');
        canSubmit = false;
      }

      if (!canSubmit) {
        Swal.fire({
          icon: 'error',
          title: 'Fout bij het invullen van het formulier',
          html: errors.join('<br>'),
          confirmButtonText: 'Sluiten',
        });
      }

      return {
        ...state,
        canSubmit,
      };
    }
    case 'SUBMIT':
      return { ...state, canSubmit: false, submitting: action.payload };
    case 'HANDLE_ERROR': {
      const errors = {};
      const nonFieldErrors = action.payload.non_field_errors;
      if (nonFieldErrors) {
        [errors.fullFieldError] = nonFieldErrors;
      }

      return { ...state, ...errors };
    }
    default:
      throw new Error(`${action.type} unknown`);
  }
}
