import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button, Container, Grid,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { IconStar, IconStarFilled, IconTrash } from '@tabler/icons-react';
import Swal2 from 'sweetalert2';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { FAQ_URL } from '../../../../Constants/URLS';
import FormLabel from '../../../../Components/Backoffice/FormLabel';
import FormInput from '../../../../Components/Backoffice/FormInput';

function FAQView() {
  const [faq, setFaq] = useState(null);
  const { id } = useParams();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');

  const fetchFAQ = async () => {
    try {
      const response = await api.get(`${FAQ_URL}${id}`);
      setFaq(response.data);
      setQuestion(response.data.question);
    } catch (error) {
      console.error('Error fetching FAQ:', error);
      navigate('/backoffice/faq');
    }
  };

  useEffect(() => {
    fetchFAQ();
  }, [id]);

  const deleteQuestion = async () => {
    const swal = await Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Deze actie kan niet ongedaan worden gemaakt.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder',
      cancelButtonText: 'Annuleren',
    });
    if (swal.isConfirmed) {
      api.del(`${FAQ_URL}${id}/`).then(() => {
        navigate('/backoffice/faq');
      });
    }
  };

  const toggleFeatured = () => {
    api.patch(`${FAQ_URL}${id}/`, { online: !faq.online }).then(() => {
      setFaq({ ...faq, online: !faq.online });
    });
  };

  const saveChange = () => {
    api.patch(`${FAQ_URL}${id}/`, { question }).then(() => {
      fetchFAQ();
    });
  };

  return faq && (
    <Container
      maxWidth="lg"
      sx={{
        ...containerStyles,
        minHeight: 'calc(100vh - 300px)',
      }}
      className="faq-view"
    >
      <Box className="header-row">
        <Box className="header-row-left">
          <h1>
            Bewerk voorgestelde vraag
          </h1>
          <Button
            className="btn btn-ghost btn-icon"
            onClick={deleteQuestion}
          >
            <IconTrash />
          </Button>
          <Button className={`featured-button ${faq.online && 'featured'}`} onClick={toggleFeatured}>
            {faq.online ? <IconStarFilled /> : <IconStar />}
          </Button>
        </Box>
        <Box />
      </Box>
      <span className="created-info">
        Gemaakt op
        {' '}
        {moment(faq.created_at).format('DD MMMM YYYY, HH:mm')}
        {' door '}
        {faq.created_by}
      </span>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormLabel label="Vraag" required />
          <FormInput onChange={setQuestion} value={question} placeholder="Voeg hier de vraag in..." />
          <div className="warning-text">
            Let op, om een antwoord te geven op een voorgestelde vraag moet er altijd een
            bijbehorende bron worden geüpload via de Bronnen-pagina.
          </div>
          <Button className="btn btn-green" disabled={question === faq.question} sx={{ mt: 3 }} onClick={saveChange}>
            Opslaan
          </Button>
          <Button sx={{ ml: 1, mt: 3 }} onClick={() => navigate('/backoffice/faq')} className="btn btn-ghost">
            Annuleren
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FAQView;
