import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container, Box,
} from '@mui/material';
import {
  IconAlertTriangle, IconAlertTriangleFilled,
  IconMessages, IconCircleCheckFilled,
} from '@tabler/icons-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { CONVERSATIONS_URL } from '../../../../Constants/URLS';
import {
  containerStyles,
} from '../../../../Components/TableStyles/TableStyles';
import './ConversationTableView.scss';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import TableSelectLabel from '../../../../Components/Backoffice/TableSelectLabel';
import Checkbox from '../../../../Components/Backoffice/Checkbox';

function ConversationTableView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [searchParams] = useSearchParams();
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const MAX_TOPICS_LENGTH = 40;
  const [assignedToMe, setAssignedToMe] = useState(false);

  const formatTopics = (topics) => {
    if (topics.length < MAX_TOPICS_LENGTH) return topics;
    return `${topics.slice(0, MAX_TOPICS_LENGTH)}...`;
  };

  const alertOptions = [
    {
      text: 'Waarschuwing',
      value: 'escalated',
    },
    {
      text: 'Geen waarschuwing',
      value: 'no_escalation',
    },
    {
      text: 'Opgelost',
      value: 'solved_escalation',
    },
  ];

  const [alertFilter, setAlertFilter] = useState([]);

  const fetchConversations = async () => {
    const response = await api.get(CONVERSATIONS_URL, {
      params: {
        page: page + 1,
        page_size: rowsPerPage,
        ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
        search,
        has_escalation: alertFilter[0],
        user: searchParams.get('user'),
        assigned_to_me: assignedToMe || null,
      },
    });
    setConversations(response.data.results);
    setTotalCount(response.data.count);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (alertFilter.length === 3) {
      setAlertFilter([]);
    }
  }, [alertFilter]);

  useEffect(() => {
    fetchConversations();
  }, [page, rowsPerPage, order, orderBy, search, alertFilter, searchParams, assignedToMe]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, order, orderBy, search, alertFilter, searchParams, assignedToMe]);

  return (
    <Container maxWidth="lg" sx={containerStyles} className="conversations-view">
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <BackofficeTitle title="Gesprekken" icon={<IconMessages />} />
        <input
          placeholder="Zoek..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className="backoffice-search"
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Checkbox onChange={() => setAssignedToMe(!assignedToMe)} checked={assignedToMe} id="assigned-to-me">
          Toegewezen aan mij
        </Checkbox>
      </Box>
      <TableContainer className="backoffice-table">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>
                <TableSelectLabel
                  onChange={setAlertFilter}
                  options={alertOptions}
                  value={alertFilter}
                >
                  <IconAlertTriangle />
                </TableSelectLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'user__full_name'}
                  direction={orderBy === 'user__full_name' ? order : 'asc'}
                  onClick={() => handleRequestSort('user__full_name')}
                >
                  Naam
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'user__email'}
                  direction={orderBy === 'user__email' ? order : 'asc'}
                  onClick={() => handleRequestSort('user__email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  Datum en tijd
                </TableSortLabel>
              </TableCell>
              <TableCell className="center">Berichten</TableCell>
              <TableCell>Onderwerpen</TableCell>
              <TableCell>Toegewezen aan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversations.length === 0 && (
              <TableRow className="no-results-table-row">
                <TableCell colspan={6}>
                  Geen resultaten gevonden
                </TableCell>
              </TableRow>
            )}
            {conversations.map((conversation) => (
              <TableRow
                key={conversation.id}
                className="table-row"
                onClick={() => navigate(`/backoffice/conversation/${conversation.id}`)}
              >
                <TableCell>
                  {conversation.escalation && (
                    conversation.escalation.handled ? (
                      <IconCircleCheckFilled className="handled-icon" />
                    ) : (
                      <IconAlertTriangleFilled className="alert-icon" />
                    )
                  )}
                </TableCell>
                <TableCell>
                  {conversation.user?.full_name || conversation.user?.email || 'Unknown User'}
                </TableCell>
                <TableCell>{conversation.user.email}</TableCell>
                <TableCell>
                  {new Date(conversation.created_at).toLocaleString([], {
                    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
                  })}
                </TableCell>
                <TableCell className="center">
                  {conversation.messages_count}
                </TableCell>
                <TableCell>
                  {formatTopics(conversation.topics.join(', '))}
                </TableCell>
                <TableCell>
                  {conversation.assigned_to}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        />
      </TableContainer>
    </Container>
  );
}

export default ConversationTableView;
