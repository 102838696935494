import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../FormInput';

function FormIconInput({
  icon, value, onChange, placeholder, disabled,
}) {
  return (
    <div className={`form-icon-input ${disabled ? 'disabled' : ''}`}>
      {icon}
      <FormInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
}

FormIconInput.propTypes = {
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

FormIconInput.defaultProps = {
  placeholder: '',
  disabled: false,
};

export default FormIconInput;
