import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container,
  Button, Box,
} from '@mui/material';
import {
  IconPlus,
  IconTestPipe,
} from '@tabler/icons-react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TEST_CASES_URL } from '../../../../Constants/URLS';
import {
  containerStyles,
  tablePaginationStyles,
} from '../../../../Components/TableStyles/TableStyles';
import './TestCasesTableView.scss';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import DutchDate from '../../../../Components/DutchDate/DutchDate';

function TestCasesTableView() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [testCases, setTestCases] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [totalCount, setTotalCount] = useState(0);

  const handleRequestSort = (property) => {
    if (property === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrderBy('');
      }
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };

  const fetchTestCases = async () => {
    let params = `?page=${page}&page_size=${rowsPerPage}`;
    if (orderBy) {
      params += `&ordering=${order === 'desc' ? '-' : ''}${orderBy}`;
    }

    const response = await api.get(`${TEST_CASES_URL}${params}`);
    setTestCases(response.data);
    setTotalCount(response.data.count);
  };

  const handleRunAll = async () => {
    const response = await api.post(`${TEST_CASES_URL}run-all/`);
    if (response.status === 200) {
      // Add polling parameter and navigate
      const newParams = new URLSearchParams(searchParams);
      newParams.set('polling', 'true');
      navigate({
        pathname: '/backoffice/testresults',
        search: newParams.toString(),
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Er is iets misgegaan bij het uitvoeren van de test vragen.',
      });
    }
  };

  useEffect(() => {
    fetchTestCases();
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    setPage(1);
  }, [order, orderBy]);

  const truncateText = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        ...containerStyles,
        minHeight: 'calc(100vh - 300px)',
      }}
      className="test-cases-table-view"
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <BackofficeTitle title="Test Vragen" icon={<IconTestPipe />} />
        <Button
          onClick={() => navigate('/backoffice/testresults')}
          className="btn btn-ghost"
          sx={{ marginLeft: 'auto' }}
        >
          Test Resultaten
        </Button>
        <Button
          onClick={() => navigate('/backoffice/testcase/new')}
          startIcon={<IconPlus />}
          className="btn btn-green"
        >
          Niew Test Vraag
        </Button>
        <Button
          onClick={() => handleRunAll()}
          className="btn btn-green"
        >
          Alle Tests Uitvoeren
        </Button>
      </Box>

      <TableContainer className="backoffice-table">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>
                Vraag
              </TableCell>
              <TableCell>
                Verwacht Antwoord
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  Gemakt Op
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testCases.map((testCase) => (
              <TableRow
                key={testCase.id}
                className="table-row"
                hover
                onClick={() => navigate(`/backoffice/testcase/${testCase.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell className="table-cell">{truncateText(testCase.question)}</TableCell>
                <TableCell className="table-cell">{truncateText(testCase.expected_answer)}</TableCell>
                <TableCell className="light">
                  <DutchDate date={moment(testCase.created_at)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(1);
        }}
        labelRowsPerPage="Rows per page:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
        style={tablePaginationStyles}
      />
    </Container>
  );
}

export default TestCasesTableView;
