import React, {
  useContext, useEffect, useReducer, useState,
} from 'react';
import {
  CircularProgress, Container, Modal, Button, InputAdornment, IconButton, TextField,
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { TbAlertTriangle, TbLockCheck, TbLockQuestion } from 'react-icons/tb';
import Swal2 from 'sweetalert2';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import {
  LOGIN_URL, RESET_PASSWORD_URL, REQUEST_URL,
} from '../../Constants/URLS';
import apiUtils from '../../Utils/ApiUtils';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import loginReducer from './LoginReducer';
import logUserAction from '../../Components/LogUserActions/LogUserActions';

function LoginView() {
  const { storeTokens, tokens } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form, formDispatch] = useReducer(loginReducer, {
    fullFieldError: '',
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
    canSubmit: false,
    submitting: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const api = apiUtils();

  function tryLogin() {
    formDispatch({ type: 'SUBMIT', payload: true });
    setLoading(true);

    api.postNoAuth(LOGIN_URL, { email: form.username, password: form.password })
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          storeTokens(response.data.access, response.data.refresh);
          // Fetch user details
          return api.get(REQUEST_URL);
        }
        // Handle incorrect username/password without else
        Swal2.fire({
          icon: 'error',
          title: 'Oeps...',
          text: 'Verkeerde gebruikersnaam en/of wachtwoord.',
        });
        setLoading(false);
        formDispatch({ type: 'SUBMIT', payload: false });
        return null;
      })
      .then((userResponse) => {
        if (userResponse?.status === 200) {
          logUserAction(userResponse.data.id, 'Login', api);
        }
        formDispatch({ type: 'SUBMIT', payload: false });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          console.log('error 401');
          Swal2.fire({
            icon: 'error',
            title: 'Oeps...',
            text: 'Verkeerde gebruikersnaam en/of wachtwoord.',
          });
        } else if (error.response.status === 403) {
          if (error.response.data.detail === 'Password expired') {
            api.postNoAuth(RESET_PASSWORD_URL, { email });
            Swal2.fire({
              icon: 'error',
              title: 'Oeps...',
              // eslint-disable-next-line max-len
              text: 'Je moet je wachtwoord elke 6 maanden wijzigen, er wordt een e-mail naar je gestuurd met instructies over hoe je je wachtwoord kunt wijzigen.',
            });
          }
        } else {
          Swal2.fire({
            icon: 'error',
            title: 'Oeps...',
            text: 'Er is een fout opgetreden.',
          });
        }
        formDispatch({ type: 'SUBMIT', payload: false });
        setLoading(false);
      });
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleResetPassword = () => {
    // Check if the email field is empty
    if (!email.trim()) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Het e-mailveld mag niet leeg zijn.',
      });
      return;
    }

    // If the email is not empty, proceed with the password reset request
    api.postNoAuth(RESET_PASSWORD_URL, { email })
      .then(() => {
        Swal2.fire({
          icon: 'success',
          title: 'Email verzonden',
          text: 'Controleer jouw email voor instructies over hoe je jouw wachtwoord opnieuw kunt instellen.',
        });
      })
      .catch((error) => {
        // Handle error - show error message
        // eslint-disable-next-line no-console
        console.error('Error resetting password: ', error);
        Swal2.fire({
          icon: 'error',
          title: 'Error',
          text: 'Er is een probleem opgetreden bij het verzenden van de e-mail voor het opnieuw instellen van het wachtwoord.',
        });
      });
    handleCloseModal();
  };

  useEffect(
    () => {
      if (form.canSubmit) tryLogin();
    },
    [form.canSubmit],
  );

  return (
    <div className="authentication-view">
      <Container maxWidth="lg" sx={{ pt: 5, pb: 15 }}>
        <a href="/">
          <img src="/default-platform-logo.png" alt="logo" className="logo" />
        </a>
      </Container>
      {tokens?.accessToken === undefined || tokens.accessToken.length === 0 ? (
        <Container maxWidth="sm" sx={{ mb: 9 }}>
          <div className="panel login-form">
            <h1>
              Inloggen
              <span>.</span>
            </h1>
            <p>
              Log in om toegang te krijgen.
            </p>
            {form.fullFieldError && <div className="formError">{form.fullFieldError}</div>}
            {form.usernameError && (
            <label className="error">
              <TbAlertTriangle />
              {form.usernameError}
            </label>
            )}
            <label>Email</label>
            <input
              id="username-input"
              type="email"
              value={form.username}
              placeholder="Email"
              autoComplete="email"
              onChange={(e) => formDispatch({ type: 'USERNAME', payload: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formDispatch({ type: 'VALIDATE' });
                }
              }}
            />
            {form.passwordError && (
            <label className="error">
              <TbAlertTriangle />
              {form.passwordError}
            </label>
            )}
            <label>Wachtwoord</label>
            <TextField
              type={showPassword ? 'text' : 'password'}
              value={form.password}
              onChange={(e) => formDispatch({ type: 'PASSWORD', payload: e.target.value })}
              placeholder="Wachtwoord"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formDispatch({ type: 'VALIDATE' });
                }
              }}
              className="password-input"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <IconEyeOff /> : <IconEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <button
              onClick={handleOpenModal}
              className="forgot-password"
              type="button"
              style={{ color: '#BAD60A' }}
            >
              Wachtwoord vergeten?
            </button>
            <div className="form-actions">
              <Button
                onClick={() => formDispatch({ type: 'VALIDATE' })}
                className="btn btn-green"
                startIcon={<TbLockCheck />}
              >
                Inloggen
              </Button>
              {loading && (
                <div className="auth-loading">
                  <CircularProgress color="inherit" sx={{ height: 'inherit' }} />
                </div>
              )}
              <a href="/signup">
                Nog geen account?
                {' '}
                <b>Registreer hier.</b>
              </a>
            </div>
            <Modal open={openModal} onClose={handleCloseModal}>
              <div className="modal-content">
                <div className="heading">
                  <TbLockQuestion />
                  <h2>Wachtwoord vergeten</h2>
                </div>
                <p>Om je wachtwoord opnieuw in te stellen, hebben we jouw email adres nodig.</p>
                <label>
                  Email
                  <span className="required">*</span>
                </label>
                <input
                  type="email"
                  value={email}
                  placeholder="E.g. jeroen@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  onClick={handleResetPassword}
                  className="btn btn-green"
                  sx={{ mt: 2 }}
                >
                  Email verzenden
                </Button>
                <Button
                  onClick={handleCloseModal}
                  className="btn btn-grey"
                  sx={{ ml: 2, mt: 2 }}
                >
                  Cancel
                </Button>
              </div>
            </Modal>
          </div>
        </Container>
      ) : (
        <Navigate
          to={{
            pathname: '/',
          }}
        />
      )}
    </div>
  );
}

LoginView.propTypes = {};

export default LoginView;
