import React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from '@mui/x-charts/PieChart';

function CustomPieChart({ data }) {
  // Render a message when there is no data
  if (!data || data.length === 0) {
    return (
      <div
        style={{
          height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <p>Geen gegevens voor deze periode</p>
      </div>
    );
  }

  return (
    <PieChart
      series={[{ data }]}
      height={250}
    />
  );
}

CustomPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default CustomPieChart;
