import React, { useContext, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container, Grid, Button, Modal, Box, CircularProgress,
} from '@mui/material';
import './CaseStudyDetailView.scss';
import {
  TbMoneybag, TbUsersGroup, TbArrowLeft, TbTrash, TbEditCircle,
  TbHelpHexagon,
} from 'react-icons/tb';
import Swal from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { CASE_STUDY_TABLE_URL, MEDIA_URL } from '../../Constants/URLS';
import CaseStudyForm from '../../Components/CaseStudyForm';
import AccessDeniedMessage from '../../Components/AccessDeniedMessage';
import LoadingScreen from '../LoadingScreen';

function CaseStudyDetailView() {
  const location = useLocation();
  const navigate = useNavigate();
  const caseStudy = location.state?.caseStudy;
  const storyEditorRef = useRef(null);

  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  // Define the roles that have access to this view
  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCaseStudy, setEditCaseStudy] = useState({});

  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const rewriteStoryAndHeadline = async (caseStudyEdit) => {
    // Assuming you have caseStudy state or it can be derived from somewhere
    const postData = {
      name: caseStudyEdit.first_name,
      salary: caseStudyEdit.salary,
      gender: caseStudyEdit.gender,
      job_role: caseStudyEdit.occupation.job_role,
      disability: caseStudyEdit.disability,
    };

    try {
      const response = await api.post(`${CASE_STUDY_TABLE_URL}rewrite-story/`, postData);
      if (response.data) {
        setEditCaseStudy((prevState) => ({
          ...prevState,
          headline: response.data.headline,
          story: response.data.story,
        }));
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to rewrite story and headline due to empty response');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error rewriting story and headline:', error);
    }
  };

  const openEditModal = () => {
    setEditCaseStudy({ ...caseStudy, story: caseStudy.story });
    setIsEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCaseStudy({ ...editCaseStudy, [name]: value });
  };

  const handleEditSubmit = async (storyContent, options = {
    showAlerts: true, closeModal: true,
  }) => {
    const updatedCaseStudy = {
      ...editCaseStudy,
      story: storyContent,
    };

    // Handle the occupation and image_link logic as before
    if (updatedCaseStudy.occupation && updatedCaseStudy.occupation.job_role) {
      updatedCaseStudy.occupation = updatedCaseStudy.occupation.job_role;
    }

    if (updatedCaseStudy.image_link && updatedCaseStudy.image_link.id) {
      updatedCaseStudy.image_link = updatedCaseStudy.image_link.id;
    }

    try {
      const response = await api.patch(`${CASE_STUDY_TABLE_URL}${updatedCaseStudy.id}/edit-case/`, updatedCaseStudy);
      location.state.caseStudy = response.data;

      if (options.closeModal) {
        setIsEditModalOpen(false);
      }

      if (options.showAlerts) {
        Swal.fire({
          icon: 'success',
          title: 'Gelukt',
          text: 'De case is aangepast',
        });
      }

      // Optionally call a callback function if provided
      if (options.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      if (options.showAlerts) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Fout bij updaten van case: ${error.message}`,
        });
      }
    }
  };

  const handlePreviewPdf = async (caseStudyId, localStory) => {
    // Call handleEditSubmit without showing alerts and without closing the modal
    setPdfModalOpen(true); // Open the modal first to show the loading spinner
    setPdfBlob(null); // Clear any previous PDF blob
    handleEditSubmit(localStory, {
      showAlerts: false,
      closeModal: false,
      onSuccess: async () => {
        try {
          const response = await api.get(`${CASE_STUDY_TABLE_URL}${caseStudyId}/download-pdf`, {
            responseType: 'blob',
          });
          if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            setPdfBlob(URL.createObjectURL(blob));
          } else {
            // eslint-disable-next-line no-console
            console.error('Failed to preview PDF');
            setPdfModalOpen(false); // Close the modal if the request fails
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error previewing PDF:', error);
          setPdfModalOpen(false); // Close the modal if the request fails
        }
      },
    });
  };

  // Function to handle delete confirmation and action
  const deleteCaseStudy = (caseId) => {
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm('Weet u zeker dat u deze case wilt verwijderen?');
    if (isConfirmed) {
      api.del(`${CASE_STUDY_TABLE_URL}${caseId}`)
        .then(() => {
          // Go back
          navigate(-1);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Fout bij het verwijderen van de case', error);
        });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (!userContext.user || !userContext.user.id) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <AccessDeniedMessage />;
  }

  if (!caseStudy) {
    return <div>Geen case gevonden</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 10, pb: 9 }}>
      <div className="single-match">
        <Modal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
        >
          <div className="modal-content" style={{ width: '80vw' }}>
            <CaseStudyForm
              newCaseStudy={editCaseStudy}
              handleInputChange={handleInputChange}
              handleSubmit={handleEditSubmit}
              story={editCaseStudy.story}
              storyEditorRef={storyEditorRef}
              handlePreviewPdf={handlePreviewPdf}
              rewriteStoryAndHeadline={rewriteStoryAndHeadline}
            />
          </div>
        </Modal>
        <Modal
          open={pdfModalOpen}
          onClose={() => setPdfModalOpen(false)}
          aria-labelledby="pdf-preview-modal"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {pdfBlob ? (
            <iframe src={pdfBlob} style={{ width: '60%', height: '70%' }} title="PDF Preview" />
          ) : (
            <Box className="loader">
              <CircularProgress />
            </Box>
          )}
        </Modal>
        <div className="header">
          <div className="match-ranking">
            <b>Admin Mode</b>
          </div>
          <div className="match-info">
            {caseStudy.image_link?.image && (
              <div className="image" style={{ backgroundImage: `url(${caseStudy.image_link.image})` }} />
            )}
            <div>
              <h1>{caseStudy.first_name}</h1>
              <h2>
                {caseStudy.occupation?.job_role}
                {' '}
                — XX jaar oud
              </h2>
            </div>
          </div>
          <div className="match-buttons">
            <Button
              onClick={handleBack}
              sx={{ mb: 2 }}
              className="btn btn-border"
              startIcon={<TbArrowLeft />}
            >
              Terug
            </Button>
            <Button
              className="btn btn-white"
              startIcon={<TbTrash />}
              onClick={() => deleteCaseStudy(caseStudy.id)}
            >
              Verwijder
            </Button>
            <Button
              className="btn btn-white"
              startIcon={<TbEditCircle />}
              onClick={openEditModal}
            >
              Aanpassen
            </Button>
          </div>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <div className="left-column">
              <div>
                <p>
                  Deze voorbeeldcase wordt u aangeboden door Credit Life. Weten hoeveel uw premie
                  van uw arbeidsongeschiktheidsverzekering kost? Bereken het zelf op
                  {' '}
                  <a href="https://creditlife.nl/inkomensgarantie" target="_blank" rel="noreferrer">
                    www.creditlife.nl/
                    <wbr />
                    inkomensgarantie
                  </a>
                  .
                </p>
                <img src="/logo-color.png" alt="Credit Life" />
                <a className="small" href="https://creditlife.nl" target="_blank" rel="noreferrer">www.creditlife.nl</a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="match-info">
              <div className="case-details">
                <p>{caseStudy.headline}</p>
                <p>
                  <TbMoneybag />
                  {' '}
                  {caseStudy.salary_display}
                  <br />
                  <TbUsersGroup />
                  {' '}
                  XXX
                </p>
                {caseStudy
                  && caseStudy.occupation
                  && caseStudy.occupation.subsector
                  && caseStudy.occupation.subsector.risks && (
                    <div>
                      <h3>
                        Meest voorkomende risico&apos;s in de
                        {' '}
                        {caseStudy.occupation.subsector.nickname}
                        {' '}
                        sector
                      </h3>
                      {caseStudy.risks.map((risk, index) => (
                        <p key={index} className="risk">
                          {risk.icon ? (
                            <img
                              alt={`Icon for ${risk.name}`}
                              src={`${MEDIA_URL}${risk.icon.icon}`} // local
                              // src={risk.icon.icon_url}
                            />
                          ) : (
                            <TbHelpHexagon />
                          )}
                          {risk.description}
                        </p>
                      ))}
                    </div>
                )}
                <h3>
                  Voorbeeldcase
                  {' '}
                  {caseStudy.first_name}
                </h3>
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: caseStudy.story }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default CaseStudyDetailView;
