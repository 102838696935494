import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Container,
  Button,
} from '@mui/material';
import {
  IconUsers,
  IconAlertTriangle,
  IconTrash,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './UserTableView.scss';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import {
  JOB_ROLES_URL, PERMISSION_ROLES, USER_TABLE_URL,
} from '../../Constants/URLS';
import AccessDeniedMessage from '../../Components/AccessDeniedMessage';
import LoadingScreen from '../LoadingScreen';
import BackofficeTitle from '../../Components/Backoffice/BackofficeTitle';

function UserTableView() {
  const [userData, setUserData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(0);
  const [jobRoles, setJobRoles] = useState({});
  const [permissionRoles, setPermissionRoles] = useState({});

  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();

  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);

  const fetchUserData = () => {
    api.get(USER_TABLE_URL, {
      params: {
        search: searchInput,
        page: page + 1,
        page_size: rowsPerPage,
      },
    }).then((response) => {
      setUserData(response.data);
      setCount(response.data.length);
    }).catch((error) => {
      console.error('Error fetching user data:', error);
    });
  };

  const handleRoleChange = async (userId, newRole) => {
    const swal = await Swal.fire({
      title: 'Bevestigen',
      text: 'Weet je zeker dat je de rol van deze gebruiker wilt wijzigen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    });

    if (!swal.isConfirmed) {
      return;
    }

    try {
      await api.patch(`${USER_TABLE_URL}${userId}/`, { role: newRole });
      fetchUserData();
      Swal.fire({
        icon: 'success',
        title: 'Rol bijgewerkt',
        text: 'De gebruikersrol is succesvol bijgewerkt.',
      });
    } catch (error) {
      console.error('Error updating user role:', error);
      Swal.fire({
        icon: 'error',
        title: 'Fout',
        text: 'Er is een fout opgetreden bij het bijwerken van de rol.',
      });
    }
  };

  const deleteUser = async (userId) => {
    const swal = await Swal.fire({
      title: 'Bevestigen',
      text: 'Weet je zeker dat je deze gebruiker wilt verwijderen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    });

    if (!swal.isConfirmed) {
      return;
    }

    try {
      await api.del(`${USER_TABLE_URL}${userId}/`);
      fetchUserData();
      Swal.fire({
        title: 'Gebruiker verwijderd',
        text: 'De gebruiker is succesvol verwijderd.',
        icon: 'success',
      });
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleRowClick = (userId) => {
    // Navigate to user detail page - implement your navigation logic here
    navigate(`/backoffice/conversations/?user=${userId}`);
  };

  useEffect(() => {
    if (hasAccess) {
      fetchUserData();
    }
  }, [hasAccess, searchInput, rowsPerPage, page]);

  useEffect(() => {
    setPage(0);
  }, [searchInput, rowsPerPage]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        const [jobRolesResponse, permissionRolesResponse] = await Promise.all([
          api.get(JOB_ROLES_URL),
          api.get(PERMISSION_ROLES),
        ]);

        setJobRoles(
          jobRolesResponse.data.reduce((acc, role) => {
            acc[role.value] = role.label;
            return acc;
          }, {}),
        );

        setPermissionRoles(
          permissionRolesResponse.data.reduce((acc, role) => {
            acc[role.value] = role.label;
            return acc;
          }, {}),
        );
      } catch (error) {
        console.error('Error loading roles:', error);
      }
    };

    loadRoles();
  }, []);

  if (!userContext.user || !userContext.user.id) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <AccessDeniedMessage />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 9 }} className="users-view">
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <BackofficeTitle title="Gebruikers" icon={<IconUsers />} />
        <input
          placeholder="Zoek..."
          value={searchInput}
          onChange={(event) => {
            setSearchInput(event.target.value);
            setPage(0);
          }}
          className="backoffice-search"
        />
      </Box>

      <TableContainer className="backoffice-table">
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead className="table-header">
            <TableRow>
              <TableCell sx={{ width: '10%' }}>Waarschuwing</TableCell>
              <TableCell>Naam</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telefoonnummer</TableCell>
              <TableCell>Beroep</TableCell>
              <TableCell>Toegang</TableCell>
              <TableCell sx={{ width: '8%' }}>Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((user) => (
              <TableRow
                key={user.id}
                hover
                onClick={(e) => {
                  if (!e.target.closest('.table-select, .btn-delete')) {
                    handleRowClick(user.id);
                  }
                }}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  {user.unhandled_escalations && (
                    <IconAlertTriangle className="alert-icon" />
                  )}
                </TableCell>
                <TableCell>
                  <b>{user.full_name}</b>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone_number}</TableCell>
                <TableCell>
                  {jobRoles[user.position_role] || user.position_role}
                </TableCell>
                <TableCell>
                  <select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    className="table-select"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {Object.entries(permissionRoles).map(([value, label]) => (
                      <option key={value} value={label}>{label}</option>
                    ))}
                  </select>
                </TableCell>
                <TableCell>
                  <Button
                    startIcon={<IconTrash />}
                    className="btn btn-ghost btn-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteUser(user.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        />
      </TableContainer>
    </Container>
  );
}

export default UserTableView;
