import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button,
  Container, Grid, CircularProgress, Backdrop, Tooltip,
} from '@mui/material';
import {
  IconBookUpload, IconChevronDown, IconFileText,
  IconLink, IconLoader2, IconTrash, IconUpload, IconX, IconPlus,
} from '@tabler/icons-react';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import FormPanel from '../../../../Components/Backoffice/FormPanel';
import FormLabel from '../../../../Components/Backoffice/FormLabel';
import FormInput from '../../../../Components/Backoffice/FormInput';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { SOURCES_URL, TOPICS_URL } from '../../../../Constants/URLS';
import OptionsPopover from '../../../../Components/Backoffice/OptionsPopover';
import FormIconInput from '../../../../Components/Backoffice/FormIconInput';
import FormTextArea from '../../../../Components/Backoffice/FormTextArea';
import TopicSelectionModal from '../../../../Components/Backoffice/TopicSelectionModal';
import ModerationHistory from '../../../../Components/Backoffice/ModerationHistory';

export default function SourceNewView() {
  // Utils
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();

  // Topic Selection
  const [generatedTopics, setGeneratedTopics] = useState([]);
  const [generatedSourceId, setGeneratedSourceId] = useState(null);
  const [loading, setLoading] = useState(false);

  // New source states
  const [title, setTitle] = useState('');
  const [selectedTopics, setSelectedTopics] = useState([]);

  // Topics utils
  const [topics, setTopics] = useState([]);
  const topicsValue = selectedTopics.map((topic) => topic.id);
  const [topicsSearch, setTopicsSearch] = useState('');
  const [topicsCount, setTopicsCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const topicsMenuOpen = Boolean(anchorEl);

  const topicsOptions = topics.map((topic) => ({
    text: topic.name,
    value: topic.id,
  }));

  const handleTopicClick = (tId) => {
    if (selectedTopics.find((t) => t.id === tId)) {
      setSelectedTopics(selectedTopics.filter((t) => t.id !== tId));
    } else {
      setSelectedTopics([...selectedTopics, topics.find((t) => t.id === tId)]);
    }
  };

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const removeTopic = (e, tId) => {
    e.stopPropagation();
    setSelectedTopics(selectedTopics.filter((t) => t.id !== tId));
  };

  // Fetch topics
  useEffect(() => {
    const fetchTopics = async () => {
      const rsp = await api.get(TOPICS_URL, {
        params: {
          search: topicsSearch,
        },
      });
      setTopics(rsp.data.results);
      setTopicsCount(rsp.data.count);
    };

    fetchTopics();
  }, [topicsSearch]);

  // Link utils
  const [link, setLink] = useState('');

  // Full text utils
  const [fullText, setFullText] = useState('');

  // File upload utils
  const [uploadedFile, setUploadedFile] = useState(null);

  const isFileDisabled = Boolean(link);
  const isLinkDisabled = Boolean(uploadedFile);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    maxFiles: 1,
    onDrop: (files) => {
      if (!isFileDisabled) {
        setUploadedFile(files[0]);
      }
    },
  });

  const removeFile = () => {
    setUploadedFile(null);
  };

  // Create Source
  const addSource = async () => {
    if (!fullText && !uploadedFile && !link) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet een bestand uploaden, een link toevoegen of aanvullende tekst invullen!',
      });
      return;
    }
    if (!title) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Je moet een titel invullen!',
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('full_text', fullText);
    formData.append('link', link);

    selectedTopics.forEach((t) => formData.append('topics', t.id));

    if (uploadedFile) {
      formData.append('source_file', uploadedFile);
    }

    try {
      const response = await api.post(SOURCES_URL, formData);
      setGeneratedTopics(response.data.generated_topics);
      setGeneratedSourceId(response.data.id);

      if (response.data.generated_topics.length === 0) {
        navigate('/backoffice/sources');
      }
    } catch (error) {
      if (error.response?.data?.source_file) {
        // Handle duplicate file error specifically
        Swal.fire({
          icon: 'warning',
          title: 'Let op',
          text: error.response.data.source_file,
          confirmButtonText: 'OK',
          showCancelButton: true,
          cancelButtonText: 'Ga naar bronnen',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate('/backoffice/sources');
          }
        });
      } else {
        // Handle other errors
        Swal.fire({
          icon: 'error',
          title: 'Oeps...',
          text: 'Er is iets misgegaan bij het toevoegen van de bron. Probeer het opnieuw.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ my: 6 }} className="source-new-view">
      <Backdrop
        className="loading-backdrop"
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <BackofficeTitle title="Voeg een bron toe" icon={<IconBookUpload />} />
        <div>
          <Button
            className="btn btn-ghost"
            onClick={() => navigate('/backoffice/sources')}
          >
            Annuleren
          </Button>
          <Button
            className="btn btn-green"
            onClick={addSource}
            sx={{ ml: 2 }}
            startIcon={loading && <IconLoader2 className="loader" />}
            disabled={loading}
          >
            Opslaan
          </Button>
        </div>
      </Box>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <FormPanel
            title="Algemeen"
            style={{ marginTop: 30 }}
          >
            <FormLabel label="Titel" required />
            <FormInput
              onChange={setTitle}
              value={title}
              placeholder="Voer titel in..."
              limit={225}
            />
            <FormLabel label="Onderwerpen" style={{ marginTop: 20 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* eslint-disable-next-line */}
              <div className="topics-input" onClick={openMenu}>
                {selectedTopics.map((topic) => (
                  // eslint-disable-next-line
                  <span className="chip" onClick={(e) => removeTopic(e, topic.id)}>
                    {topic.name}
                    <IconX />
                  </span>
                ))}
                {selectedTopics.length === 0 && (
                  <span className="placeholder">
                    -- Selecteer onderwerpen --
                  </span>
                )}
                <IconChevronDown className="chevron" />
              </div>
              <Tooltip
                title="Voeg een nieuw onderwerp toe."
                placement="bottom"
                arrow
              >
                <Button href="/backoffice/topics/add" target="_blank" className="btn btn-secondary btn-icon">
                  <IconPlus />
                </Button>
              </Tooltip>
            </Box>
            <OptionsPopover
              options={topicsOptions}
              value={topicsValue}
              onChange={handleTopicClick}
              search={topicsSearch}
              onSearchChange={setTopicsSearch}
              count={topicsCount}
              anchorEl={anchorEl}
              open={topicsMenuOpen}
              closeMenu={() => setAnchorEl(null)}
            />
          </FormPanel>

          <FormPanel
            title="Bron"
            subtitle="Kies hoe je de bron wilt uploaden: plak tekst, upload een bestand of voeg een link naar een externe bron toe."
            style={{ marginTop: 30 }}
          >
            <Tooltip
              arrow
              title={isFileDisabled ? 'Je kunt slechts één optie tegelijkertijd gebruiken.' : ''}
            >
              <div
                className="slot-wrapper"
                role="button"
                tabIndex={0}
              >
                {uploadedFile ? (
                  <div className="uploaded-file">
                    <div className="content">
                      <div className="icon">
                        <IconFileText />
                      </div>
                      <h4>
                        {uploadedFile.path}
                      </h4>
                    </div>
                    <button
                      type="button"
                      aria-labelledby="delete-file"
                      onClick={removeFile}
                    >
                      <IconTrash />
                    </button>
                  </div>
                ) : (
                  // eslint-disable-next-line
                  <div {...getRootProps({className: `source-dropzone ${isFileDisabled ? 'disabled' : ''}`})}>
                    {/* eslint-disable-next-line */}
                    <input {...getInputProps()} />
                    <IconUpload />
                    <div>
                      <h4>Sleep hier je bestand naartoe of klik om te uploaden</h4>
                      <p>*.pdf, *.txt, *.doc(x), *.csv, *.xls(x)</p>
                    </div>
                  </div>
                )}
              </div>
            </Tooltip>

            <div className="separator">of</div>

            <Tooltip
              arrow
              title={isLinkDisabled ? 'Je kunt slechts één optie tegelijkertijd gebruiken.' : ''}
            >
              <div className={`slot-wrapper ${isLinkDisabled ? 'slot-disabled' : ''}`}>
                <FormLabel label="Link" />
                <FormIconInput
                  icon={<IconLink />}
                  value={link}
                  onChange={setLink}
                  placeholder="Voer hier een link in......"
                  disabled={isLinkDisabled}
                />
              </div>
            </Tooltip>

            <div className="separator">of</div>

            <FormLabel label="Extra tekst" />
            <small style={{ display: 'block', marginBottom: 10 }}>
              Extra tekst is ook gebruikt door de chatbot
              om de bron te verduidelijken of extra informatie te geven.
            </small>
            <FormTextArea
              value={fullText}
              onChange={setFullText}
              placeholder="Voe hier extra tekst in..."
              rows={8}
            />
          </FormPanel>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormPanel title="Controle" style={{ marginTop: 30 }}>
            <ModerationHistory
              createdAt={new Date()}
              createdBy={userContext.user.first_name}
              moderationHistory={[]}
              refresh={() => {
              }}
              contentType="source"
              status="unchecked"
              disabled
            />
          </FormPanel>
        </Grid>
      </Grid>

      {generatedTopics.length > 0 && (
        <TopicSelectionModal
          topics={generatedTopics}
          sourceId={generatedSourceId}
        />
      )}
    </Container>
  );
}
