import React from 'react';
import {
  IconBook,
  IconChartHistogram,
  IconFolderCog,
  IconFolderPlus,
  IconFolders, IconGavel,
  IconHelpOctagon, IconMailForward, IconMessageChatbot,
  IconMessageExclamation,
  IconMessages, IconTags,
  IconUsers,
  IconTestPipe,
} from '@tabler/icons-react';

const BACKOFFICE_MENU = [
  {
    title: 'Backoffice',
    icon: <IconChartHistogram />,
    link: '/data-dashboard',
    roles: ['Admin', 'Staff'],
  },
  {
    title: 'Gebruikers',
    icon: <IconUsers />,
    children: [
      {
        title: 'Gebruikers Beheer',
        link: '/user-table',
        icon: <IconUsers />,
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Uitnodigingen',
        link: '/invitations',
        icon: <IconMailForward />,
        roles: ['Admin', 'Staff'],
      },
    ],
  },
  {
    title: 'Cases',
    icon: <IconFolders />,
    roles: ['Admin', 'Staff'],
    children: [
      {
        title: 'Case Beheer',
        link: '/cases',
        icon: <IconFolderCog />,
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Case Generator',
        link: '/case-content-generator',
        icon: <IconFolderPlus />,
        roles: ['Admin', 'Staff'],
      },
    ],
  },
  {
    title: 'Feedback',
    icon: <IconMessageExclamation />,
    link: '/feedback',
    roles: ['Admin', 'Staff'],
  },
  {
    title: 'Chatbot',
    icon: <IconMessageChatbot />,
    children: [
      {
        title: 'Overzicht',
        icon: <IconMessageChatbot />,
        link: '/chat-overview',
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Bronnen',
        icon: <IconBook />,
        link: '/sources',
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Voorgestelde vragen',
        icon: <IconHelpOctagon />,
        link: '/faq',
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Onderwerpen',
        icon: <IconTags />,
        link: '/topics',
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Gesprekken',
        icon: <IconMessages />,
        link: '/conversations',
        roles: ['Admin', 'Staff'],
      },
      {
        title: 'Testen',
        icon: <IconTestPipe />,
        link: '/testresults',
        roles: ['Admin', 'Staff'],
      },
    ],
  },
  {
    title: 'Juridische Teksten',
    icon: <IconGavel />,
    link: '/legal-texts',
    roles: ['Admin'],
  },
];

export default BACKOFFICE_MENU;
