import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {
  IconFileText, IconChevronDown, IconChevronUp,
  IconAlertTriangleFilled, IconThumbDownFilled, IconThumbUpFilled,
} from '@tabler/icons-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function ConversationMessage({ message, sender }) {
  const [showSources, setShowSources] = useState(false);
  const navigate = useNavigate();

  // eslint-disable-next-line
  const satisfied = message.sentiment === 6 ? 'satisfied' : (message.sentiment === -1 ? 'dissatisfied' : '');

  return (
    <div
      className={`conversation-message ${message.sentiment === -2 ? 'alert-message' : ''}`}
      id={`msg-${message.id}`}
    >
      <div className={`message-content ${message.role}`}>
        <div className="message-header">
          <span
            className="author"
          >
            {sender}
          </span>
          <span className="time">{moment(message.created_at).format('HH:mm')}</span>
        </div>
        <p><ReactMarkdown>{message.message}</ReactMarkdown></p>
        {message.sources && message.sources.length > 0 && (
          // eslint-disable-next-line
          <div
            className="sources"
            onClick={() => setShowSources(!showSources)}
          >
            <div className="sources-count">
              {message.sources.length}
            </div>
            <div className="sources-icon">
              <IconFileText />
            </div>
            <div className="sources-text">
              Bijgevoegde bronnen
            </div>
            {showSources ? <IconChevronUp className="sources-caret" /> : <IconChevronDown className="sources-caret" />}
          </div>
        )}
        {showSources && message.sources.map((source) => (
          // eslint-disable-next-line
          <div
            className="source"
            onClick={() => navigate(`/backoffice/sources/${source.id}`)}
          >
            <div className="icon">
              <IconFileText />
            </div>
            <h4>
              {source.title}
            </h4>
          </div>
        ))}
      </div>
      {message.role === 'assistant' && satisfied !== '' && (
        <div className={`satisfaction ${satisfied ? 'satisfied' : 'dissatisfied'}`}>
          {satisfied ? (
            <IconThumbUpFilled />
          ) : (
            <IconThumbDownFilled />
          )}
        </div>
      )}
      <div className={`alert-icon ${message.role}`}>
        <IconAlertTriangleFilled />
      </div>
    </div>
  );
}

ConversationMessage.propTypes = {
  // eslint-disable-next-line
  message: PropTypes.object.isRequired,
  sender: PropTypes.string.isRequired,
};

export default ConversationMessage;
