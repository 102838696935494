import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import './App.scss';
import { NotificationContainer } from 'react-notifications';
import history from './Utils/history';
import Pages from './Constants/pages';
import UserProvider from './Providers/UserProvider/UserProvider';
import MatchesProvider from './Providers/MatchesProvider/MatchesProvider';
import NotificationProvider from './Providers/NotificationProvider/NotificationProvider';
import CustomRouter from './Utils/CustomRouters';
import AuthenticatedRoute from './Components/AuthenticatedRoute/AuthenticatedRoute';
import NavigationBar from './Components/NavigationBar';
import Footer from './Components/Footer';
import FeedbackModal from './Components/FeedbackModal';
import CaseStudyDetailView from './Views/CaseStudyDetailView/CaseStudyDetailView';
import PasswordResetConfirm from './Views/PasswordResetConfirm';
import CaseBeheerProvider from './Providers/CaseBeheerProvider/CaseBeheerProvider';
import Chatbot from './Components/Chatbot';

function App() {
  const pages = Pages();

  return (
    <UserProvider>
      <CustomRouter history={history}>
        <div className="App">
          <NotificationProvider>
            <>
              <NotificationContainer />
              <NavigationBar pages={pages} />
              <MatchesProvider>
                <CaseBeheerProvider>
                  <Routes>
                    <Route path="/password-reset-confirm/:uidb64/:token" component={PasswordResetConfirm} />
                    <Route path="/backoffice/case-study-detail" element={<CaseStudyDetailView />} />
                    {
                    Object.keys(pages).map((key, idx) => (
                      <Route
                        key={idx}
                        path={pages[key].path}
                        element={pages[key].loginNeeded
                          // eslint-disable-next-line max-len
                          ? <AuthenticatedRoute element={pages[key].element} roles={pages[key].roles} />
                          : pages[key].element}
                      />
                    ))
                  }
                    <Route path="*" element={<Navigate to="/home" />} />
                  </Routes>
                </CaseBeheerProvider>
              </MatchesProvider>
              <FeedbackModal pages={pages} />
              <Chatbot pages={pages} />
              <Footer pages={pages} />
            </>
          </NotificationProvider>
        </div>
      </CustomRouter>
    </UserProvider>
  );
}

export default App;
