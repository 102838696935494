import React from 'react';
import PropTypes from 'prop-types';

function DutchDate({ date, includeTime = true }) {
  // Dutch month names in lowercase
  const dutchMonths = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
  ];

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // Get date components
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = dutchMonths[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    // Format time if needed
    const time = includeTime
      ? `, ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`
      : '';

    return `${day} ${month} ${year}${time}`;
  };

  return (
    <span className="text-sm">
      {formatDate(date)}
    </span>
  );
}

DutchDate.propTypes = {
  date: PropTypes.string.isRequired,
  includeTime: PropTypes.bool,
};

DutchDate.defaultProps = {
  includeTime: true,
};

export default DutchDate;
