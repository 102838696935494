import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconBan,
  IconCheck,
  IconClock,
  IconLoader,
  IconLoaderQuarter,
  IconUserCheck,
  IconChecks,
  IconX,
  IconQuestionMark, IconToggleRight, IconToggleLeft, IconArchive, IconArchiveOff, IconPencil,
} from '@tabler/icons-react';
import moment from 'moment';
import { Box, Button, Popover } from '@mui/material';
import Swal from 'sweetalert2';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import { ASSIGNMENT_URL, REVIEW_URL, USER_URL } from '../../../Constants/URLS';
import FormLabel from '../FormLabel';
import FormTextArea from '../FormTextArea';
import OptionsPopover from '../OptionsPopover';

function ModerationHistory({
  status, moderationHistory, createdAt, createdBy,
  contentType, objectId, refresh, disabled, auditLogs,
}) {
  const [firstCheck, setFirstCheck] = useState([]);
  const [secondCheck, setSecondCheck] = useState([]);
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [anchorElRejection, setAnchorElRejection] = useState(null);
  const openRejection = Boolean(anchorElRejection);
  const [rejectionFeedback, setRejectionFeedback] = useState('');

  const lastReview = moderationHistory.slice().reverse().find((item) => item.type === 'review');

  const [users, setUsers] = useState([]);
  const [usersSearch, setUsersSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState([]);
  const [anchorElAssign, setAnchorElAssign] = useState(null);
  const openAssign = Boolean(anchorElAssign);
  const [usersCount, setUsersCount] = useState(0);

  useEffect(() => {
    if (moderationHistory.length > 0) {
      const lastAssignment = moderationHistory.slice().reverse().find((item) => item.type === 'assignment');
      if (lastAssignment) {
        if (lastAssignment.assigned_to) {
          setSelectedUser([lastAssignment.assigned_to_id]);
        } else {
          setSelectedUser([]);
        }
      }

      // Only proceed if lastReview is defined
      if (lastReview && lastReview.status === 'approved' && status === 'pending') {
        const lastReviewIndex = moderationHistory.findIndex((item) => item.id === lastReview.id);
        const firstPart = moderationHistory.slice(0, lastReviewIndex + 1);
        setFirstCheck(firstPart);
        const secondPart = moderationHistory.slice(lastReviewIndex + 1);
        setSecondCheck(secondPart);
        return;
      }
      if (lastReview && lastReview.status === 'approved' && status === 'approved') {
        const reversedHistory = moderationHistory.slice().reverse();
        const secondLastReview = reversedHistory.find((item, index) => item.type === 'review' && index > 0);
        const secondLastReviewIndex = moderationHistory.findIndex(
          (item) => item.id === secondLastReview.id,
        );
        const firstPart = moderationHistory.slice(0, secondLastReviewIndex + 1);
        setFirstCheck(firstPart);
        const secondPart = moderationHistory.slice(secondLastReviewIndex + 1);
        setSecondCheck(secondPart);
        return;
      }
    }
    setFirstCheck(moderationHistory);
  }, [moderationHistory]);

  const firstCheckStatus = firstCheck.length > 0 && firstCheck.slice().reverse().find((item) => item.type === 'review')
    ? firstCheck.slice().reverse().find((item) => item.type === 'review').status
    : 'pending';

  const secondCheckStatus = secondCheck.length > 0 && secondCheck.slice().reverse().find((item) => item.type === 'review')
    ? secondCheck.slice().reverse().find((item) => item.type === 'review').status
    : 'pending';

  const approve = () => {
    const data = {
      content_type: contentType,
      object_id: objectId,
      status: 'approved',
    };

    api.post(REVIEW_URL, data).then(() => {
      refresh();
    });
  };

  useEffect(() => {
    setRejectionFeedback('');
  }, [openRejection]);

  const reject = () => {
    if (!rejectionFeedback) {
      Swal.fire({
        icon: 'error',
        title: 'Fout',
        text: 'Feedback is verplicht',
      });
      return;
    }
    api.post(REVIEW_URL, {
      content_type: contentType,
      object_id: objectId,
      status: 'rejected',
      comment: rejectionFeedback,
    }).then(() => {
      refresh();
      setAnchorElRejection(null);
    });
  };

  useEffect(() => {
    api.get(USER_URL, {
      params: {
        search: usersSearch,
      },
    }).then((response) => {
      setUsers(response.data.results);
      setUsersCount(response.data.count);
    });
  }, [usersSearch]);

  const handleAssign = (u) => {
    const lastAssignment = moderationHistory.slice().reverse().find((item) => item.type === 'assignment');
    if (lastAssignment && lastAssignment.assigned_to && u === lastAssignment.assigned_to_id) {
      api.post(ASSIGNMENT_URL, {
        content_type: contentType,
        object_id: objectId,
        assigned_to: null,
      }).then(() => {
        refresh();
        setAnchorElAssign(null);
      });
      return;
    }
    api.post(ASSIGNMENT_URL, {
      content_type: contentType,
      object_id: objectId,
      assigned_to: u,
    }).then(() => {
      refresh();
      setAnchorElAssign(null);
    });
  };

  const getPreviousAssignment = (assignment) => {
    const reversedHistory = moderationHistory.slice().reverse();
    const currentAssignmentIndex = reversedHistory.findIndex((item) => item.id === assignment.id);
    const previousAssignment = reversedHistory.find((item, index) => item.type === 'assignment' && index > currentAssignmentIndex);
    return previousAssignment.assigned_to;
  };

  const parseAuditIcon = (text) => {
    if (text.includes('online')) return <IconToggleRight />;
    if (text.includes('offline')) return <IconToggleLeft />;
    if (text.includes('gearchiveerd')) return <IconArchive />;
    if (text.includes('gedearchiveerd')) return <IconArchiveOff />;
    return <IconQuestionMark />;
  };

  const moderationActions = (second = false) => (
    <>
      <Box sx={{ mt: -2 }} />
      {(!lastReview || lastReview.created_by_id !== userContext.user.id || lastReview.status !== 'approved') && (
        <div className={`review ${second ? 'second' : ''}`}>
          <div className="timeline-node" />
          <Button
            className="btn btn-green"
            startIcon={second ? <IconChecks /> : <IconCheck />}
            sx={{ mt: 2 }}
            onClick={approve}
            disabled={disabled}
          >
            {second ? 'Tweede' : 'Eerste'}
            {' '}
            controle voltooien
          </Button>
        </div>
      )}
      {(!lastReview || lastReview.created_by_id !== userContext.user.id || lastReview.status !== 'rejected') && (
        <div className={`review ${second ? 'second' : ''}`}>
          <div className="timeline-node" />
          <Button
            className="btn btn-destructive"
            sx={{ mt: 1 }}
            startIcon={<IconBan />}
            onClick={(e) => setAnchorElRejection(e.currentTarget)}
            disabled={disabled}
          >
            Afwijzen
          </Button>
          <Popover
            open={openRejection}
            anchorEl={anchorElRejection}
            onClose={() => setAnchorElRejection(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            className="rejection-popover"
          >
            <div className="popover-tile">
              <h4>
                {second ? 'Tweede' : 'Eerste'}
                {' '}
                controle afwijzen
              </h4>
              <button
                onClick={() => setAnchorElRejection(null)}
                type="button"
                className="close"
                aria-labelledby="close"
              >
                <IconX />
              </button>
            </div>
            <div className="popover-content">
              <FormLabel label="Feedback" required />
              <FormTextArea
                value={rejectionFeedback}
                onChange={setRejectionFeedback}
                placeholder="Laat een reactie achter..."
                rows={3}
              />
              <Button
                className="btn btn-destructive"
                sx={{ mt: 2 }}
                onClick={reject}
              >
                Afwijzen
              </Button>
              <Button
                className="btn btn-ghost"
                onClick={() => setAnchorElRejection(null)}
                sx={{ mt: 2, ml: 1 }}
              >
                Annuleren
              </Button>
            </div>
          </Popover>
        </div>
      )}
      <div className={`review ${second ? 'second' : ''}`}>
        <div className="timeline-node" />
        <div className="separator">of</div>
      </div>
      <div className={`review ${second ? 'second' : ''}`}>
        <div className="timeline-node" />
        <Button
          className="btn btn-secondary"
          startIcon={<IconUserCheck />}
          sx={{ mb: 2 }}
          onClick={(e) => setAnchorElAssign(e.currentTarget)}
          disabled={disabled}
        >
          Toewijzen aan...
        </Button>
        <OptionsPopover
          open={openAssign}
          anchorEl={anchorElAssign}
          closeMenu={() => setAnchorElAssign(null)}
          options={users.map((user) => ({
            text: user.full_name,
            value: user.id,
          }))}
          value={selectedUser}
          onChange={handleAssign}
          count={usersCount}
          search={usersSearch}
          onSearchChange={setUsersSearch}
        />
      </div>
    </>
  );

  return (
    <div className="reviews">
      <div className="review">
        <div className="timeline-node">
          <div className="node">
            <IconClock />
          </div>
        </div>
        <p>
          <b>{createdBy}</b>
          {' '}
          heeft de bron gemaakt.
          {' '}
          <small>{moment(createdAt).format('DD.MM.YYYY, HH:mm')}</small>
        </p>
      </div>
      <div className={`review ${firstCheckStatus}`}>
        <div className="timeline-node">
          <div className="node">
            {firstCheckStatus === 'approved' ? <IconCheck /> : (
              <>
                {firstCheckStatus === 'rejected' ? <IconBan /> : <IconLoaderQuarter />}
              </>
            )}
          </div>
        </div>
        <div>
          <h4>
            Eerste controle
          </h4>
        </div>
      </div>
      {firstCheck.map((review) => (
        <div className={`review ${firstCheckStatus} simple`} key={review.id}>
          <div className="timeline-node">
            <div className="node">
              {review.type === 'assignment' ? <IconUserCheck /> : (
                <>
                  {review.status === 'rejected' && <IconBan />}
                  {review.status === 'approved' && <IconCheck />}
                  {review.status === 'edited' && <IconPencil />}
                </>
              )}
            </div>
          </div>
          <p>
            <b>{review.created_by}</b>
            {' '}
            {review.type === 'assignment' ? (
              <>
                {review.assigned_to !== null ? (
                  <>
                    heeft
                    {' '}
                    <b>{review.assigned_to}</b>
                    {' '}
                    toegewezen.
                  </>
                ) : (
                  <>
                    heeft de toewijzing van
                    {' '}
                    <b>{getPreviousAssignment(review)}</b>
                    {' '}
                    verwijderd.
                  </>
                )}
              </>
            ) : (
              <>
                heeft de bron
                {' '}
                {review.status === 'approved' && 'geaccepteerd'}
                {review.status === 'rejected' && 'afgewezen'}
                {review.status === 'edited' && 'aangepast'}
                .
              </>
            )}
            {' '}
            <small>{moment(review.created_at).format('DD.MM.YYYY HH:mm')}</small>
            {review.comment && (
              <span className="comment">
                {review.comment}
              </span>
            )}
          </p>
        </div>
      ))}
      {firstCheckStatus !== 'approved' && moderationActions()}
      <div className={`review ${secondCheckStatus} ${secondCheck.length === 0 ? 'second' : ''}`}>
        <div className="timeline-node">
          <div className="node">
            {secondCheckStatus === 'approved' ? <IconChecks /> : (
              <>
                {firstCheckStatus === 'approved' ? <IconLoader /> : <IconLoaderQuarter />}
              </>
            )}
          </div>
        </div>
        <div>
          <h4>
            Tweede controle
          </h4>
        </div>
      </div>
      {firstCheckStatus !== 'approved' && (
        <div className="review second">
          <div className="timeline-node" />
          <p>De eerste controle moet voltooid zijn voordat de tweede controle gedaan kan worden.</p>
        </div>
      )}
      {secondCheck.map((review, index) => (
        <div
          className={`review ${index === secondCheck.length - 1 ? '' : firstCheckStatus} simple ${index === secondCheck.length - 1 && auditLogs.length === 0 ? 'second' : ''}`}
          key={review.id}
        >
          <div className="timeline-node">
            <div className="node">
              {review.type === 'assignment' ? <IconUserCheck /> : <IconCheck />}
            </div>
          </div>
          <p>
            <b>{review.created_by}</b>
            {' '}
            {review.type === 'assignment' ? (
              <>
                {review.assigned_to !== null ? (
                  <>
                    heeft
                    {' '}
                    <b>{review.assigned_to}</b>
                    {' '}
                    toegewezen.
                  </>
                ) : (
                  <>
                    heeft
                    {' '}
                    <b>{getPreviousAssignment(review)}</b>
                    {' '}
                    niet toegewezen.
                  </>
                )}
              </>
            ) : 'heeft de bron geaccepteerd.'}
            {' '}
            <small>{moment(review.created_at).format('DD.MM.YYYY HH:mm')}</small>
          </p>
        </div>
      ))}
      {firstCheckStatus === 'approved' && secondCheckStatus !== 'approved' && moderationActions(true)}
      {auditLogs.map((log, idx) => (
        <div
          className={`review simple ${idx === auditLogs.length - 1 ? 'second' : ''}`}
          key={log.id}
        >
          <div className="timeline-node">
            <div className="node">
              {parseAuditIcon(log.text)}
            </div>
          </div>
          <p>
            {log.user && (
              <b>
                {log.user_first_name}
                {' '}
              </b>
            )}
            {log.text}
            {' '}
            <small>{moment(log.created_at).format('DD.MM.YYYY HH:mm')}</small>
          </p>
        </div>
      ))}
    </div>
  );
}

ModerationHistory.propTypes = {
  status: PropTypes.string.isRequired,
  moderationHistory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      created_by: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      assigned_to: PropTypes.string,
      assigned_to_id: PropTypes.string,
      comment: PropTypes.string,
    }),
  ).isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  objectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  refresh: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  auditLogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      user: PropTypes.number,
      user_first_name: PropTypes.string,
    }),
  ),
};

ModerationHistory.defaultProps = {
  disabled: false,
  auditLogs: [],
};

export default ModerationHistory;
