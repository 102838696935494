import axios from 'axios';
import { mapToAuthRequest } from './RequestUtils';

export default function apiUtilsHook(config) {
  const { getConfig, refresh, logout } = config;

  function mapToAuthRequestLoc(request) {
    return mapToAuthRequest(request, getConfig, refresh, logout);
  }

  return {
    get(url, configParams = {}) {
      const request = (conf) => axios.get(url, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    put(url, data = {}, configParams = {}) {
      const request = (conf) => axios.put(url, data, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    patch(url, data = {}, configParams = {}) {
      const request = (conf) => axios.patch(url, data, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    post(url, data = {}, configParams = {}) {
      const request = (conf) => axios.post(url, data, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    del(url, configParams = {}) {
      const request = (conf) => axios.delete(url, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    postNoAuth(url, data = {}, configParams = {}) {
      return axios.post(url, data, configParams).catch((error) => {
        throw error;
      });
    },
    getNoAuth(url, configParams = {}) {
      return axios.get(url, configParams);
    },
    postStream(url, data = {}, configParams = {}) {
      const request = async (conf) => {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            ...conf.headers,
            ...configParams.headers,
            'Content-Type': 'application/json',
            Accept: 'application/json, text/plain, */*',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.body.getReader();
      };

      return mapToAuthRequestLoc(request);
    },
  };
}
